.App {
  text-align: center;
  position: relative;
  height: 100vh;
}

#page-wrap {
  min-height: calc(100vh - 100px);
}

//* {
//  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
//  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
//  outline: none !important;
//}