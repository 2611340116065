body {
  margin: 0;
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  * {
    box-sizing: border-box;
  }
  height: 100%;
}

.inner-content {
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
}
